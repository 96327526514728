<template>
    <div class="relative">
           <button @click="toggle" class="text-gray-900 formation align-bottom mr-2 flex flex-row items-center w-full text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline  hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
            <span class="cursor-pointer flex items-center i18n-locale">
               <!--<img class="blob green-2" src="https://img.icons8.com/color/96/000000/youtube-play.png"/>-->
               <svg  class="blob green-2 text-blue-600 fill-current"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/></svg>
            </span>
           </button>
           
     </div>   
   </template>
   
   <script>
   import { mixin as clickaway } from "vue-clickaway";
   export default {
    mixins: [clickaway],
    props:{
       user:{type:Object},
     },
     data() {
       return { 
         open: false,
         curentUser:this.user,
       };
     },
      async mounted() {
        
      },
     computed: {
       
     },
     methods: {
       async toggle() {
          this.$vm2.open('ModalFormation');
       },
     }
   }
   </script>
   <style scoped>
   
   .blob.green-2 {
     box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
     animation: pulse-green 2s infinite;
   }
   .formation img{
     width: 28px;
   }
   .dropdown_warhouse{
     min-width: 170px;
   }
   @keyframes pulse-green {
     0% {
       transform: scale(0.95);
       box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
     }
     
     70% {
       transform: scale(1);
       box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
     }
     
     100% {
       transform: scale(0.95);
       box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
     }
   }
   .vs-dropdown--item {
       text-align: left;
       -webkit-transition: all .2s ease;
       transition: all .2s ease;
       position: relative;
       z-index: 100;
       border-radius: 5px;
       width: calc(100% - 6px);
       margin: 0 3px;
       list-style: none;
       font-weight: 400!important;
       font-size: .9375em;
   }
    .vs-dropdown--item-link {
       display: flex;
       -moz-box-align: center;
       align-items: center;
       -moz-box-pack: justify;
       justify-content: space-between;
       padding: 5px 1px;
       cursor: pointer;
   }
   .vs-dropdown--menu{
   border: 1px solid rgba(0,0,0,.1);
   position: relative;
   }
   .vs-dropdown--menu--after {
       top: 0;
       right: 30px;
       position: absolute;
       width: 10px;
       height: 10px;
       display: block;
       background: #fff;
       -webkit-transform: rotate(
   45deg
   ) translate(-7px);
       transform: rotate(
   45deg
   ) translate(-7px);
       border-top: 1px solid rgba(0,0,0,.1);
       border-left: 1px solid rgba(0,0,0,.1);
       z-index: 10;
       -webkit-box-sizing: border-box;
       box-sizing: border-box;
   }
   .checkboxWrapper{
       height: 20px;
       width: 20px;
       margin-left: 10px;
   }
   </style>