<template>
 <div v-on-clickaway="away" class="relative">
        <button @click="toggle" class="text-gray-900 flex flex-row items-center w-full px-1 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0  hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
         <span class="cursor-pointer flex items-center i18n-locale">
            <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale" />
            <span class="hidden sm:block ml-2">{{ getCurrentLocaleData.lang }}</span>
             <!-- <svg fill="currentColor" viewBox="0 0 20 20" :class="{'rotate-180': open, 'rotate-0': !open}" class="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> -->
             <i class="material-icons"><span v-if="!open">arrow_drop_down</span><span v-else>arrow_drop_up</span></i>
         </span>
         
        </button>
        <div v-if="open" x-transition:enter="transition ease-out duration-100" x-transition:enter-start="transform opacity-0 scale-95" x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75" x-transition:leave-start="transform opacity-100 scale-100" x-transition:leave-end="transform opacity-0 scale-95" class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-40">
          <div class="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800 vs-dropdown--menu">
            <li @click="updateLocale('en')" class="text-gray-900 hover:text-green-500 vs-dropdown--item"><a class="vs-dropdown--item-link"><img class="h-4 w-5 mr-1" src="@/assets/flags/en.svg" alt="en" /> &nbsp;English</a></li>
            <li @click="updateLocale('fr')" class="text-gray-900 hover:text-green-500 vs-dropdown--item"><a class="vs-dropdown--item-link"><img class="h-4 w-5 mr-1" src="@/assets/flags/fr.svg" alt="fr" /> &nbsp;Français</a></li>
          </div>
          <div class="vs-dropdown--menu--after"></div>
        </div>
  </div>   
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
 mixins: [clickaway],
  data() {
    return { 
      open: false
    };
  },
   async mounted() {
    
   },
  computed: {
    i18n_locale_img () {
      return require(`@/assets/flags/${this.$i18n.locale}.svg`)
    },
    getCurrentLocaleData () {
      const locale = this.$i18n.locale
      if (locale === 'en')      return { flag: 'us', lang: 'English'    }
      else if (locale === 'fr') return { flag: 'fr', lang: 'Français'     }
    }
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    away() {
      this.open = false;
    },
    updateLocale (locale) {
    //   if (locale == 'ar') this.$vs.rtl = true;
    //   else this.$vs.rtl = false;

      localStorage.setItem('language', locale);
      this.$i18n.locale = locale;
      this.open=false;
    }
  }
}
</script>
<style scoped>
.vs-dropdown--item {
    text-align: left;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    position: relative;
    z-index: 100;
    border-radius: 5px;
    width: calc(100% - 6px);
    margin: 0 3px;
    list-style: none;
    font-weight: 400!important;
    font-size: .9375em;
}
 .vs-dropdown--item-link {
    display: flex;
    align-items: center;
    background: inherit!important;
    color: inherit!important;
    cursor: pointer;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    position: relative;
}
.vs-dropdown--menu{
border: 1px solid rgba(0,0,0,.1);
position: relative;
}
.vs-dropdown--menu--after {
    top: 0;
    right: 30px;
    position: absolute;
    width: 10px;
    height: 10px;
    display: block;
    background: #fff;
    -webkit-transform: rotate(
45deg
) translate(-7px);
    transform: rotate(
45deg
) translate(-7px);
    border-top: 1px solid rgba(0,0,0,.1);
    border-left: 1px solid rgba(0,0,0,.1);
    z-index: 10;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
</style>