<template>
 <div v-on-clickaway="away" class="relative">
        <button @click="toggle('principal')" :class="sous_warehouses && sous_warehouses.length>0?'pb-0 pt-0':''" class="text-gray-900 header-ware flex flex-row items-center w-full px-2 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
         <span class="cursor-pointer flex items-center i18n-locale">
            <img class="h-4 w-5 blob green" :src="country_img || 'https://img.icons8.com/material-outlined/2x/image.png'" :alt="country_data.code" />
            <span class="hidden sm:block ml-2">{{ country_data.name }} <sup v-if="idWharhouse" class="font-bold uppercase">({{idWharhouse.currency}})</sup></span>
            <i class="material-icons"><span v-if="!open">arrow_drop_down</span><span v-else>arrow_drop_up</span></i>
         </span>
         
        </button>
        <div v-if="open" x-transition:enter="transition ease-out duration-100" x-transition:enter-start="transform opacity-0 scale-95" x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75" x-transition:leave-start="transform opacity-100 scale-100" x-transition:leave-end="transform opacity-0 scale-95" class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-60">
          <div class="px-1 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800 vs-dropdown--menu">
            <li  v-for="(item, index) in warehousesAll" :key="index"  @click="changeWrahouse(item.code,'principal')" class="text-gray-900 hover:text-green-500 vs-dropdown--item"><a class="vs-dropdown--item-link ware-item"><img class="h-4 w-5 mr-1" :src="$f.getCountryCode(item.code)" :alt="item.code" /> &nbsp;{{item.name}} <div class="checkboxWrapper"> <i class="material-icons" style="font-size: 20px;" ><span v-if="country_data.code==item.code">fiber_manual_record</span><span v-else>panorama_fish_eye</span></i></div></a></li>
          </div>
          <div class="vs-dropdown--menu--after"></div>
        </div>
        <div v-if="sous_warehouses && sous_warehouses.length>0 && currentModel && ['orders', 'shippings'].indexOf(currentModel) != -1  && ['ToRemind', 'CancelledOrder','NRP','TeleConsultant'].indexOf(user.type) == -1" class="sous-warehouses">
          <button @click="toggle('sous')" class="text-gray-900 header-ware flex flex-row items-center w-full px-2 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
            <span class="cursor-pointer flex items-center i18n-locale">
                <img class="h-4 w-5 ml-2" src="https://img.icons8.com/material-two-tone/48/null/home-page.png"/>
                <span  class="hidden pl-1 title sm:block capitalize">{{ country_sous_data.name }}</span>
                <i class="material-icons"><span v-if="!sous_open">arrow_drop_down</span><span v-else>arrow_drop_up</span></i>
            </span>
          </button>
          <div v-if="sous_open" x-transition:enter="transition ease-out duration-100" x-transition:enter-start="transform opacity-0 scale-95" x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75" x-transition:leave-start="transform opacity-100 scale-100" x-transition:leave-end="transform opacity-0 scale-95" class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-60">
              <div  class="dropdown_warhouse px-1 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800 vs-dropdown--menu">
                <li  v-for="(item, index) in sous_warehouses" :key="index"  @click="changeWrahouse(item,'sous')" class="text-gray-900 hover:text-green-500 vs-dropdown--item">
                  <a class="vs-dropdown--item-link ware-item capitalize">
                    <img class="h-4 w-5 mr-1" src="https://img.icons8.com/ios-filled/50/null/visit.png" :alt="item.code" /> &nbsp;{{item.name}} <div class="checkboxWrapper"> <i class="material-icons" style="font-size: 20px;" ><span v-if="country_sous_data._id.toString()==item._id.toString()">fiber_manual_record</span><span v-else>panorama_fish_eye</span></i></div></a></li>
              </div>
              <div class="sous-warehouse vs-dropdown--menu--after"></div>
          </div>
        </div>
  </div>   
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
 mixins: [clickaway],
 props: {
      user: { type: Object},
      idWharhouse: { type: Object},
      sous_idWharhouse: { type: String},
      sous_warehouses: { type: Array },
  },
  data() {
    return { 
      open: false,
      sous_open:false,
      options:this.$countries,
      country_img:"",
      warehousesAll:[],
      country_data:{},
      country_sous_data:{},
      currency:"",
      currentModel:this.$store.state.model
    };
  },
   async mounted() {
     this.warehousesAll= await this.warehouses;
     console.log('sous_warehouses---------nnnn',this.sous_warehouses)
     this.getCurrency();
    this.country_img= await this.country_locale_img;
    this.country_data= await this.getCurrentLocaleData;
    if(this.sous_idWharhouse) this.country_sous_data=this.sous_warehouses.find(x => x._id.toString() === this.sous_idWharhouse.toString())
   },
  computed: {
    async warehouses(){
      return this.$store.getters["wharhouse/warehouses"]({user:_.pick(this.user,['countries'])});
    },
    async warhouseSelected(){
      return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.user,['countries'])});
    },
    async country_locale_img () {
      return this.$f.getCountryCode(await this.warhouseSelected)
    },
    async getCurrentLocaleData () {
      return {name:this.$f.getCountryNameByCode(this.options,await this.warhouseSelected),code:await this.warhouseSelected}
      
    },
  },
  watch: {
      'warhouseSelected': async function(oldVal, newVal) {
             this.getCurrency();
      },
      '$store.state.model': async function(oldVal, newVal) {
        console.log('new update model mise ajour-----',oldVal);
        this.currentModel=oldVal;
       // console.log('new update pr_version mise ajour-----',this.$store.state.pr_version);
    },
  },
  methods: {
    async getCurrency(){
      const country=await this.warhouseSelected;
      // await this.$store.dispatch('wharhouse/getCurrency',{country:await this.warhouseSelected}).then((res) => {
      //       this.currency=res;
      //   })
      
    // if(country) this.currency=this.warehousesAll.find(o => o.code === country).currency; 
     // console.log('this.currency-----',this.currency)
    }, 
     async getWarehouses(){
      await this.$store.dispatch('wharhouse/getWarehouses',{user:_.pick(this.user,['countries'])}).then((res) => {
            this.warehousesAll=res;
        })
    }, 
  //  async changeWrahouse(value){
  //      this.$store.dispatch('wharhouse/changeWrahouse',{warhouse:value});
  //      console.log('await this.warhouseSelected',await this.warhouseSelected)
  //      this.country_img= await this.country_locale_img;
  //      this.country_data= await this.getCurrentLocaleData; 
  //      localStorage.setItem('countryW', value);
  //      this.open=false;
  //   },
    async changeWrahouse(value,type){
       console.log('value',value)
       if(type=='principal'){
          //console.log('sous ware new 111-----------------',this.$store.state.sous_warehouses)

          if(this.user.type != 'Seller') {
              const resW = await this.$server.search("warehouses", { country:value,all:true,limit:10}); 
              if(resW.content && resW.content.results) {
                 let sousW=resW.content.results.filter(item => !item.is_primary).sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0)).map(warehouse => ({ _id: warehouse._id, name: warehouse.name }));
                 if(sousW && sousW.length>0) sousW.splice(0, 0, {_id:'all',name:'all'});
                 this.country_sous_data=sousW.find(x => x._id.toString() === this.sous_idWharhouse.toString());
                 this.$store.dispatch("actionSousWarehouses",{sous_warehouses:sousW}); 
              }else this.$store.dispatch("actionSousWarehouses",{sous_warehouses:[]}); 
           }else this.$store.dispatch("actionSousWarehouses",{sous_warehouses:[]}); 
           
          this.$store.dispatch('wharhouse/changeWrahouse',{warhouse:value,type:'principal'});
          console.log('await this.warhouseSelected',await this.warhouseSelected)

          


       //  console.log('sous ware new 222-----------------',this.$store.state.sous_warehouses)
          this.country_img= await this.country_locale_img;
          this.country_data= await this.getCurrentLocaleData; 
          localStorage.setItem('countryW', value);
          this.open=false;
       }else{
         this.$store.dispatch('wharhouse/changeWrahouse',{warhouse:value._id.toString(),type:'sous'});
         console.log('change sous warehouse------',this.sous_warehouses)
         console.log('country_sous_data------',this.sous_warehouses.find(x => x._id.toString() === value._id.toString()))
         this.country_sous_data=this.sous_warehouses.find(x => x._id.toString() === value._id.toString())
         localStorage.setItem('sous_countryW',value._id.toString());
         this.sous_open=false;
       }
      
    },
    async toggle(type) {
      if(type=='principal'){
        this.open = !this.open; this.sous_open = false;
        console.log('getCurrentLocaleData---------nnnn',await this.getCurrentLocaleData)
        this.country_data= await this.getCurrentLocaleData;
        await this.getWarehouses();
        console.log('warehouses---------nnnn',await this.warehouses)
        await this.getCurrency();
      }else{
        this.open = false;
        this.sous_open = !this.sous_open;
      } 
    },
    away() {
      this.open = false;
      this.sous_open = false;
    },
  }
}
</script>
<style scoped>
.sous-warehouses .title{
  font-size: 11px;
}
.sous-warehouses .vs-dropdown--item-link {
  padding: 4px 1px;
}
.sous-warehouses button{
  padding: 0;
  position: relative;
  box-sizing: border-box;
  margin-left: 28px;
  margin-top: 3px;
}
.sous-warehouses img {
  width: 14px;
  height: auto;
}

.sous-warehouses::before {
  position: absolute;
    top: 14px;
    left: 17px;
    width: 18px;
    height: 2px;
    margin: auto;
    content: "";
    background-color: #666;
}
.sous-warehouses{
  position: relative;
  margin-top: -5px;
}
.sous-warehouses i{
  font-size: 20px;
}
.sous-warehouse.vs-dropdown--menu--after{
  right: 85px;
}
.sous-warehouses::after {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 17px;
    width: 2px;
    content: "";
    background-color: #666;
    height: 14px;
}
.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}
@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
.vs-dropdown--item {
    text-align: left;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    position: relative;
    z-index: 100;
    border-radius: 5px;
    width: calc(100% - 6px);
    margin: 0 3px;
    list-style: none;
    font-weight: 400!important;
    font-size: .9375em;
}
 .vs-dropdown--item-link {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding: 5px 1px;
    cursor: pointer;
}
.vs-dropdown--menu{
border: 1px solid rgba(0,0,0,.1);
position: relative;
}
.vs-dropdown--menu--after {
    top: 0;
    right: 30px;
    position: absolute;
    width: 10px;
    height: 10px;
    display: block;
    background: #fff;
    -webkit-transform: rotate(
45deg
) translate(-7px);
    transform: rotate(
45deg
) translate(-7px);
    border-top: 1px solid rgba(0,0,0,.1);
    border-left: 1px solid rgba(0,0,0,.1);
    z-index: 10;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.checkboxWrapper{
    height: 20px;
    width: 20px;
    margin-left: 10px;
}
.ware-item{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>