<template>
  <div v-on-clickaway="away" class="relative">
    <a class="text-gray-600 block py-1 pr-3 relative cursor-pointer" ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
       <i class="material-icons">notifications</i><span class="dot-count vs-avatar--count badgeNumber bg-red-500">4</span>
    </a>
    <div ref="popoverDropdownRef" class="bg-white text-base z-50 float-left mt-2 list-none text-left rounded-md shadow-lg min-w-48" style="width:20rem;" v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">
      <div class="dropdown-head bg-primary bg-pattern rounded-top">
            <div class="p-3">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                    </div>
                    <div class="col-auto dropdown-tabs">
                        <span class="badge bg-light-subtle text-gray-800 fs-13"> 4 New</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-2 pt-0"> 
             <a @click="$vm2.open('ModalFormation')" href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100">
              <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://img.icons8.com/color/48/youtube-play.png" alt="avatar">
              <p class="text-gray-600 text-sm mx-2">
                  Video explicative de l'intégration Google Sheets
              </p>
            </a>
             <a @click="$vm2.open('ModalFormation')" href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100">
              <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://img.icons8.com/color/48/youtube-play.png" alt="avatar">
              <p class="text-gray-600 text-sm mx-2">
                  Video explicative de l'upsell
              </p>
            </a>
            <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100">
              <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-upselling-merchandising-flaticons-lineal-color-flat-icons.png" alt="avatar">
              <p class="text-gray-600 text-sm mx-2">
                  <b>Upsell product</b> maintenant disponible
              </p>
            </a>
            <router-link :to="{ path: `/orders/api#sheet`}" class="flex items-center px-4 py-3  hover:bg-gray-100">
              <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://img.icons8.com/color/96/google-sheets.png" alt="avatar">
              <p class="text-gray-600 text-sm mx-2">
                  Integration <b>google sheet</b> maintenant disponible
              </p>
            </router-link>
            
            
          </div> 
      <!-- <a href="#" class="block bg-gray-800 text-white text-center font-bold py-2">See all notifications</a> -->
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  props: {
      user: { type: Object},
  },
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    toggle() {
      this.dropdownPopoverShow = !this.dropdownPopoverShow;
    },
    away() {
      this.dropdownPopoverShow = false;
    },
  },
};
</script>
<style scoped>
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.row {
  --vz-gutter-x: 1.5rem;
  --vz-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 2px;
  margin-right:  2px;
  margin-left:  2px;
}
.row > * {
  position: relative;
}
.col {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  height: 35px;
}
.row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right:  2px;
  padding-left:  2px;
  margin-top:  2px;
}
.fs-16 {
  font-size: 16px !important;
}
.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}
.bg-pattern {
  background-color: #6590e5 !important;
}
.rounded-top {
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em !important;
}
.badge {
  line-height: .95;
  display: inline-block;
  border-radius:0.25em;
  padding:0.35em 0.65em;
  font-weight:500;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.fs-13 {
  font-size: 13px !important;
}
.bg-light-subtle {
  background-color: #f8fafb !important;
}
.badgeNumber {
   position: absolute;
    z-index: 100;
    width: auto;
    height: auto;
    top: -3px;
    right: 4px;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: .625em;
    color: #fff;
    pointer-events: none;
}
</style>
