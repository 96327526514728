<template>
  <div   ref="btnDropdownRef" v-on:mouseover="onOver($event)" v-on:mouseleave="onLeave">
    <a
      class="text-gray-600 block"
      href="javascript:void(0)"
    >
      <div class="items-center flex profile">
        <span
          class="w-10 h-10 text-sm text-white bg-green-600  inline-flex items-center justify-center rounded-full"
        >
                <i class="material-icons mx-2">person</i>
        </span>
        <span  class="cursor-pointer text-green-600 ml-2 title">{{user.fullName}} 
          <span class="details">{{user.type}} </span>
        </span>
       
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
    
       <router-link to="/profil"  class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800" >
           <span class="flex items-center">
                    <i class="material-icons">person_outline</i>
                <span class="mx-4 font-medium">{{$t('profil')}}</span>
            </span>
        </router-link>
     <!-- <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      >
        My products
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      >
         My orders
      </a>-->
      <div class="h-0 my-2 border border-solid border-gray-200" />
      <a
        href="javascript:void(0);"  @click="logout"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      >
          <span class="flex items-center">
                    <i class="material-icons">input</i>
                <span class="mx-4 font-medium">{{$t('logout')}}</span>
            </span>
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

import image from "@/assets/logo.jpg";


export default {
  props: {
      user: { type: Object},
      idWharhouse: { type: Object},
   },
  data() {
    return { 
      dropdownPopoverShow: false,
      image: image,
    };
  },
  methods: {
     logout() {
      this.$jwtService.destroyToken();
      location.href = "/";
      //this.$router.push("home");
    },
    onOver: function (event) {
      event.preventDefault();
      if (!this.dropdownPopoverShow) {
         this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    onLeave() {
        this.dropdownPopoverShow = false;
    }
  },
};
</script>
